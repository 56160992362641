var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "login" } }, [
    _c("div", { staticClass: "ui grid stackable" }, [
      _c("div", { staticClass: "five wide column" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "six wide column" },
        [
          _vm.xAuthInProgress
            ? _c("div", [_c("i", { staticClass: "icon circle notch spin" })])
            : _c("div", [
                _c("h2", [_vm._v("Welcome Back")]),
                _vm._v(" "),
                _c("p", [_vm._v("Sign in with your credentials")]),
                _vm._v(" "),
                _c("form", { staticClass: "ui large form" }, [
                  _c("div", { staticClass: "ui segment" }, [
                    _c("div", { staticClass: "field" }, [
                      _c("div", { staticClass: "ui left icon input" }, [
                        _c("i", { staticClass: "user icon" }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.username,
                              expression: "username",
                            },
                          ],
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            name: "username",
                            placeholder: "Username",
                            autocorrect: "off",
                            autocapitalize: "none",
                          },
                          domProps: { value: _vm.username },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.login.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.username = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("div", { staticClass: "ui left icon input" }, [
                        _c("i", { staticClass: "key icon" }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          attrs: {
                            type: "password",
                            name: "password",
                            placeholder: "Password",
                          },
                          domProps: { value: _vm.password },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.login.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.password = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ui fluid large primary submit button",
                        class: { loading: _vm.loggingIn },
                        on: { click: _vm.login },
                      },
                      [_vm._v("Login")]
                    ),
                  ]),
                ]),
              ]),
          _vm._v(" "),
          _c("Message", { attrs: { bindTo: "error" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "five wide column" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }